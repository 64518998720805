<template>
  <div class="cutting-page">
    <div class="top">
      <el-select
        v-model="searchForm.type"
        size="small"
        placeholder="请选择推送类型"
      >
        <el-option label="全部" :value="null"></el-option>
        <el-option label="群成员" :value="0"></el-option>
        <el-option label="群组" :value="1"></el-option>
        <el-option label="账号" :value="2"></el-option>
        <el-option label="归属地" :value="3"></el-option>
        <el-option label="方案" :value="4"></el-option>
        <el-option label="群组图片" :value="5"></el-option>
      </el-select>
      <el-date-picker
        v-model="searchForm.startDate"
        type="date"
        style="margin-left:10px"
        value-format="yyyy-MM-dd"
        placeholder="选择开始时间"
        size="small"
      ></el-date-picker>
      <el-date-picker
        v-model="searchForm.endDate"
        type="date"
        value-format="yyyy-MM-dd"
        style="margin-left:10px"
        size="small"
        placeholder="选择结束时间"
      ></el-date-picker>

      <el-button
        type="primary"
        size="mini"
        style=" margin-left:10px"
        @click="searchFun"
        :loading="loading"
      >
        查询
      </el-button>
      <el-button
        class="el-icon-refresh-left"
        size="mini"
        style=" margin-left:10px"
        @click="refresh"
        :loading="loading"
      >
        刷新
      </el-button>
    </div>
    <div class="child" v-loading="loading">
      <el-table
        style="margin-bottom:10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        :data="list"
        border
        :height="windowsHeight - 220"
      >
        <el-table-column prop="targetId" label="群组ID/账号ID/方案名称">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 4" class="cutting-table-span">
              {{ scope.row.targetName }}
            </span>
            <span
              v-else
              class="cutting-table-span"
              @click="
                detailFun(
                  scope.row.targetId,
                  scope.row.targetType,
                  scope.row.id
                )
              "
            >
              {{ scope.row.targetId }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="导出类型">
          <template slot-scope="scope">
            <el-link type="primary" v-if="scope.row.type == 0">
              群成员
            </el-link>
            <el-link type="primary" v-else-if="scope.row.type == 1">
              群组
            </el-link>
            <el-link type="success" v-else-if="scope.row.type == 2">
              账号
            </el-link>
            <el-link type="danger" v-else-if="scope.row.type == 3">
              归属地
            </el-link>
            <el-link type="danger" v-else-if="scope.row.type == 4">
              方案
            </el-link>
            <el-link v-else-if="scope.row.type == 5">
              群组图片
            </el-link>
            <el-link v-else>全部</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="导出状态">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.state === 0
                  ? 'primary'
                  : scope.row.state === 1
                  ? 'success'
                  : 'danger'
              "
            >
              {{
                scope.row.state === 0
                  ? '正在导出'
                  : scope.row.state === 1
                  ? '导出成功'
                  : '导出失败'
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="导出时间"></el-table-column>
        <el-table-column width="80" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="downeFun(scope.row)"
              v-if="scope.row.url"
            >
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :margin="0"
        :sizesArr="[30, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>

    <!-- 手机详情弹框 -->
    <userDetail :placeVisible="placeVisible" @closePlace="closePlace" />
  </div>
</template>

<script>
import { importLog } from '@/api/importLog'
export default {
  name: 'ImportLog',
  data() {
    return {
      loading: false,
      list: [],
      current: 1,
      limit: 30,
      total: 0,
      searchForm: {
        type: null, //1群组 2账号 3归属地
        startDate: null,
        endDate: null
      },
      placeVisible: false
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },

  created() {
    this.getPage()
  },
  methods: {
    // 关闭弹框
    closePlace() {
      this.placeVisible = false
    },
    searchFun() {
      this.current = 1
      this.getPage()
    },
    refresh() {
      this.getPage()
    },
    getPage() {
      this.loading = true
      importLog(this.current, this.limit, this.searchForm).then(data => {
        this.list = data.items
        this.total = data.all_count
        this.loading = false
      })
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    },
    detailFun(targetId, targetType, id) {
      if (targetType == 1) {
        this.$router.push('/group/detail/' + targetId)
      } else if (targetType == 2) {
        this.$router.push('/user/detail/' + targetId)
      }
      readApi(id)
    },
    downeFun(row) {
      if (row.type == 5) {
        let href = row.url
        window.open(href, '_blank')
      } else {
        let href =
          process.env.VUE_APP_BASE_API + `/importLog/download/${row.id}`
        window.open(href, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cutting-page {
  padding: 10px;
  width: 100%;
  height: calc(100vh - 80px);
  background: #f0f2f5;
  .top {
    padding: 10px;
    background: white;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    border-radius: 5px;
  }
  .child {
    margin-top: 5px;
    padding: 10px;
    padding-bottom: 1px;
    background: white;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    border-radius: 5px;
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
  .cutting-table-span {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: rgb(37, 122, 233);
    }
  }
}
</style>
