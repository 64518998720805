import request from '@/plugins/axios'

//  分页
export function importLog(current, limit, data) {
  return request({
    url: `/importLog/${current}/${limit}`,
    method: 'post',
    data
  })
}

